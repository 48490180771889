import React from 'react';

import './Button.scss';

const Button = ({ isClose, isCorrect, isUsed, value, onClick }) => {
    let className = 'keyboard-button';
    if ( isCorrect )
        className += ' keyboard-button--correct';
    else if ( isClose )
        className += ' keyboard-button--close';
    else if ( isUsed )
        className += ' keyboard-button--used';
    
    const handleClick = (event) => {
        event.preventDefault();
        if ( typeof onClick === 'function' )
            onClick.call(this, value);
    }

    return <div className={className} onClick={handleClick}>
        {value}
        <div className='feature' />
    </div>
}

export default Button;