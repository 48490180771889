import React from 'react';
import Button from '../../atoms/button/Button';

import './Keyboard.scss';

const Keyboard = ({ correct_keys, close_keys, used_keys, onKeyPress }) => {

    const handleKeyPress = value => {
        if ( typeof onKeyPress === 'function' )
            onKeyPress.call(this, value);
    }

    const isClose = letter => close_keys.indexOf(letter) > -1;
    const isCorrect = letter => correct_keys.indexOf(letter) > -1;
    const isUsed = letter => used_keys.indexOf(letter) > -1;

    return (
        <div className='keyboard'>
            <div className='row'>
                {'q,w,e,r,t,y,u,i,o,p'.split(',').map((letter, index) => {
                    letter = letter.toUpperCase()
                    return <Button value={letter} key={index} onClick={handleKeyPress} isClose={isClose(letter)} isCorrect={isCorrect(letter)} isUsed={isUsed(letter)} />
                })}
            </div>
            <div className='row'>
                {'a,s,d,f,g,h,j,k,l'.split(',').map((letter, index) => {
                    letter = letter.toUpperCase()
                    return <Button value={letter} key={index} onClick={handleKeyPress} isClose={isClose(letter)} isCorrect={isCorrect(letter)} isUsed={isUsed(letter)} />
                })}
            </div>
            <div className='row'>
                {'enter,z,x,c,v,b,n,m,BKSP'.split(',').map((letter, index) => {
                    letter = letter.toUpperCase()
                    return <Button value={letter} key={index} onClick={handleKeyPress} isClose={isClose(letter)} isCorrect={isCorrect(letter)} isUsed={isUsed(letter)} />
                })}
            </div>
        </div>
    )
};

export default Keyboard;