import React, { useState } from 'react';

import './Board.scss';

const Block = ({ value, word, row, index, is_current }) => {
    let className = 'board-block';
    
    if ( !is_current && value !== '') {
        if ( value?.toUpperCase() === word[index].toUpperCase() )
            className += ' board-block--correct';
        else if ( word.toUpperCase().indexOf(value?.toUpperCase()) > -1 )
            className += ' board-block--close';
    }

    return <div className={className} key={`${row}-${index}`}>
        {value || <>&nbsp;</>}
    </div>
}

const Board = ({ guesses, correct_word, guess_limit, current_guess, active_line }) => {
    const word_length = correct_word.length;

    const boardRow = index => {
        const row = [];
        let guess = guesses[index]
        if ( guess != undefined && guess.length >= word_length  ) {
            let guess = guesses[index];
            for ( let i = 0; i < word_length; i++ )
            {
                row.push(<Block row={index} is_current={false} value={guess[i]} word={correct_word} index={i} key={i} />);
            }
            return <div className='row' key={index}>{row}</div>;
        }

        for ( let i = 0; i < word_length; i++ )
        {
            if ( (active_line) === index )
            {
                let value = '';
                if ( current_guess?.length >= i )
                    value = current_guess[i];
                row.push(<Block is_current={true} word={correct_word} value={value} index={i} key={i} />);
            }
            else
            {
                row.push(<Block is_current={false} word={correct_word} value='' index={i} key={i} />);
            }
        }   

        return <div className='row' key={index}>{row}</div>;
    }

    const printBoard = limit => {
        const board = [];
        for ( let row = 0; row < limit; row++ )
        {
            board.push(boardRow(row));
        }
        return board;
    }

    return <div className='board'>
        {printBoard(guess_limit)}
    </div>
}

Board.defaultProps = {
    guesses: [],
    correct_word: 'UNKNOWN',
    active_line: 1,
    guess_limit: 6,
    current_guess: ''
}

export default Board;