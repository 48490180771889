import axios from "axios";

const headers = {
    'Content-Type': 'application/json'
};

const base_url = 'https://lingle.mhprogramming.co.uk/api/lingle';

const apiClient = axios.create({ baseURL: base_url, headers: headers });

const lingleApi = {
    post: apiClient.post,
    get: apiClient.get
};

export default lingleApi;