import React, { useEffect, useState } from 'react';
import './App.css';
import Board from './ui/molecules/board/Board';

import Keyboard from './ui/molecules/keyboard/Keyboard';

import lingleApi from './api/API';

function App() {
  const [guess, setGuess] = useState(``);
  const [guesses, setGuesses] = useState([]);
  const [nearKeys, setNEarKeys] = useState([]);
  const [correctKeys, setCorrectKeys] = useState([]);
  const [usedKeys, setUsedKeys] = useState([]);

  const [word, setWord] = useState('');

  useEffect(async () => {
    const response = await lingleApi.get('/GetWord');
    setWord(response.data.word);
    console.log(response);
  }, []);

  const handleGuess = letter => {
    if ( letter.toUpperCase() === 'ENTER')
    {
      enterPressed();
      return;
    }
    if ( letter.toUpperCase() === 'BKSP' )
    {
      setGuess(guess.slice(0, guess.length-1));
      return;
    }

    if ( guess.length === word.length )
      return;
    
    setGuess(`${guess}${letter[0]}`);
  }

  const enterPressed = () => {
    let compare_guess = guess.toUpperCase();
    let compare_word = word.toUpperCase();

    if ( compare_guess.length !== compare_guess.length )
    return;

    if ( compare_guess === compare_word ) {

    }
    else {
      for ( var i = 0; i < compare_guess.length; i++ ) {
        let letter = compare_guess[i];
        if ( word[i] === letter && correctKeys.indexOf(letter) === -1)
          correctKeys.push(letter);
        else if ( word.indexOf(letter) > -1 && correctKeys.indexOf(letter) === -1 && nearKeys.indexOf(letter) === -1 )
          nearKeys.push(letter);
        else if ( usedKeys.indexOf(letter) === -1 )
          usedKeys.push(letter);
      }
    }

    guesses.push(guess);
    setGuess(``);
  }
  return (
    <div>
      <Board guesses={guesses} current_guess={guess} correct_word={word} active_line={guesses.length} />
      <Keyboard onKeyPress={handleGuess} close_keys={nearKeys} correct_keys={correctKeys} used_keys={usedKeys} />
    </div>
  );
}

export default App;
